import { FC, SVGProps } from 'react'

export const Multiply: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92754 2.23193C2.41367 1.862 3.11776 1.94333 3.50018 2.41357L7.025 6.74785L10.5498 2.41357C10.9322 1.94333 11.6363 1.862 12.1225 2.23193C12.6086 2.60185 12.6927 3.28293 12.3102 3.75317L8.44992 8.5L12.3102 13.2468C12.6927 13.7171 12.6086 14.3982 12.1225 14.7681C11.6363 15.138 10.9322 15.0567 10.5498 14.5864L7.025 10.2521L3.50018 14.5864C3.11776 15.0567 2.41367 15.138 1.92754 14.7681C1.44141 14.3982 1.35734 13.7171 1.73976 13.2468L5.60008 8.5L1.73976 3.75317C1.35734 3.28293 1.44141 2.60185 1.92754 2.23193Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92754 1.73193C3.41367 1.362 4.11776 1.44333 4.50018 1.91357L8.025 6.24785L11.5498 1.91357C11.9322 1.44333 12.6363 1.362 13.1225 1.73193C13.6086 2.10185 13.6927 2.78293 13.3102 3.25317L9.44992 8L13.3102 12.7468C13.6927 13.2171 13.6086 13.8982 13.1225 14.2681C12.6363 14.638 11.9322 14.5567 11.5498 14.0864L8.025 9.75215L4.50018 14.0864C4.11776 14.5567 3.41367 14.638 2.92754 14.2681C2.44141 13.8982 2.35734 13.2171 2.73976 12.7468L6.60008 8L2.73976 3.25317C2.35734 2.78293 2.44141 2.10185 2.92754 1.73193Z"
        fill="#98EC2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7762 1.53296C3.3683 1.08239 4.22553 1.17953 4.69419 1.75581L8.02505 5.8516L11.3559 1.75581C11.8246 1.17953 12.6818 1.08239 13.2739 1.53296C13.8709 1.98723 13.977 2.82958 13.5042 3.41089L9.77221 7.99998L13.5042 12.5891C13.977 13.1704 13.8709 14.0127 13.2739 14.467C12.6818 14.9176 11.8246 14.8204 11.3559 14.2441L8.02505 10.1484L4.69419 14.2441C4.22553 14.8204 3.3683 14.9176 2.7762 14.467C2.17922 14.0127 2.07311 13.1704 2.54585 12.5891L6.27789 7.99998L2.54585 3.41089C2.07311 2.82958 2.17922 1.98723 2.7762 1.53296ZM4.30627 2.07128C4.01009 1.70708 3.45913 1.64157 3.07898 1.93085C2.70371 2.21642 2.64168 2.73625 2.93377 3.09542L6.79409 7.84224C6.86881 7.93413 6.86881 8.06583 6.79409 8.15771L2.93377 12.9045C2.64168 13.2637 2.70371 13.7835 3.07898 14.0691C3.45913 14.3584 4.01009 14.2929 4.30627 13.9287L7.83109 9.59439C7.87857 9.53602 7.94981 9.50213 8.02505 9.50213C8.10029 9.50213 8.17153 9.53602 8.21901 9.59439L11.7438 13.9287C12.04 14.2929 12.591 14.3584 12.9711 14.0691C13.3464 13.7835 13.4084 13.2637 13.1163 12.9045L9.25601 8.15771C9.18129 8.06583 9.18129 7.93413 9.25601 7.84224L13.1163 3.09542C13.4084 2.73625 13.3464 2.21642 12.9711 1.93085C12.591 1.64158 12.04 1.70708 11.7438 2.07128L8.21901 6.40557C8.17153 6.46394 8.10029 6.49783 8.02505 6.49783C7.94981 6.49783 7.87857 6.46394 7.83109 6.40557L4.30627 2.07128Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_23183_230576">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
