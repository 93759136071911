import { FC, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { useWalletTokenLoans } from '../../LoansTokenPage'
import { MultiplyPair } from '../constants'
import { HeaderList } from './components/HeaderList'
import LeveragePositionCard from './components/LeveragePositionCard'

import styles from './LeveragePositionsContent.module.less'

type LeveragePositionsContentProps = {
  pair: MultiplyPair
}
const LeveragePositionsContent: FC<LeveragePositionsContentProps> = ({ pair }) => {
  const { connected } = useWallet()

  const { loans, isLoading } = useWalletTokenLoans(pair.marketTokenType)

  const filteredLoans = useMemo(
    () => loans.filter((loan) => loan.fraktBond.leverageBasePoints),
    [loans],
  )

  const noData = !filteredLoans.length && !isLoading
  const showEmptyList = noData || !connected

  if (showEmptyList) return <EmptyList message="No positions found" />

  return (
    <div className={styles.content}>
      <HeaderList />

      {isLoading && <Loader />}

      {!noData && (
        <div className={styles.cardsList}>
          {filteredLoans.map((loan) => (
            <LeveragePositionCard key={loan.publicKey} loan={loan} />
          ))}
        </div>
      )}
    </div>
  )
}

export default LeveragePositionsContent
