import { FC } from 'react'

import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { DisplayValue } from '@banx/components/TableComponents'

import { getTokenDecimals } from '@banx/utils'

import styles from './PlaceOfferSection.module.less'

interface BorrowerMessageProps {
  loanValue: string
  tokenType: LendingTokenType
  marketUpfrontFee: number
}

export const BorrowerMessage: FC<BorrowerMessageProps> = ({
  loanValue,
  marketUpfrontFee,
  tokenType,
}) => {
  const decimals = getTokenDecimals(tokenType)

  const loanValueToNumber = parseFloat(loanValue) || 0
  const loanValueWithProtocolFee =
    (loanValueToNumber - loanValueToNumber * (marketUpfrontFee / BASE_POINTS)) * decimals

  return (
    <p className={styles.borrowerMessage}>
      Borrower sees: {<DisplayValue value={loanValueWithProtocolFee} />}
    </p>
  )
}
