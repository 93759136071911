import { useAssetMode } from '@banx/store/common'

import { ExternalLinks, NavigationsLinks } from './components'
import { EXTERNAL_LINKS, SECONDARY_NAVIGATION_LINKS } from './constants'
import { getNavigationLinks } from './helpers'

import styles from './Navbar.module.less'

export const Navbar = () => {
  const { currentAssetMode } = useAssetMode()

  return (
    <div className={styles.navbar}>
      <NavigationsLinks links={getNavigationLinks(currentAssetMode)} />
      <NavigationsLinks links={SECONDARY_NAVIGATION_LINKS} />
      <ExternalLinks links={EXTERNAL_LINKS} />
    </div>
  )
}
