import { FC } from 'react'

import { BN } from 'fbonds-core'
import { BASE_POINTS, SECONDS_IN_YEAR } from 'fbonds-core/lib/fbond-protocol/constants'
import { calculateCurrentInterestSolPure } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'
import moment from 'moment'

import { StatInfo } from '@banx/components/StatInfo'
import { DisplayValue } from '@banx/components/TableComponents'

import { CollateralToken } from '@banx/api/tokens'
import { bnToFixed, bnToNumberSafe } from '@banx/utils'

import { MultiplyPair } from '../../constants'
import { calculateTokenLoanBorrowAmount } from '../../helpers'
import { LeverageSimpleOffer } from '../../types'

import styles from './Summary.module.less'

type SummaryProps = {
  selectedOffer: LeverageSimpleOffer
  conversionRate: number
  totalCollateralAmount: BN
  userEnteredCollateralAmount: BN
  collateral: CollateralToken
  pair: MultiplyPair
}

export const Summary: FC<SummaryProps> = ({
  totalCollateralAmount,
  conversionRate,
  selectedOffer,
  userEnteredCollateralAmount,
  collateral,
  pair,
}) => {
  const borrowAmount = calculateTokenLoanBorrowAmount(
    totalCollateralAmount.sub(userEnteredCollateralAmount),
    conversionRate,
  )

  const marketUpfrontFee = collateral?.collateral.upfrontFee || 0

  const upfrontFee = new BN(borrowAmount).mul(new BN(marketUpfrontFee)).div(new BN(BASE_POINTS))

  const statClassNames = {
    value: styles.fixedStatValue,
  }

  const netApr = calculateNetApr({
    totalCollateralAmount,
    conversionRate,
    offer: selectedOffer,
    userEnteredCollateralAmount,
    collateral,
    pair,
  })

  const upfrontFeePercent = marketUpfrontFee / 100

  return (
    <div className={styles.summary}>
      {/* <StatInfo
        label="Price impact"
        value={0}
        valueType={VALUES_TYPES.PERCENT}
        classNamesProps={statClassNames}
        tooltipText="Change in an asset's market price caused by executing a large trade relative to market liquidity"
        flexType="row"
      /> */}
      <StatInfo
        label="Upfront fee"
        value={
          <DisplayValue strictTokenType={pair.marketTokenType} value={bnToNumberSafe(upfrontFee)} />
        }
        tooltipText={`${upfrontFeePercent}% upfront fee charged on the loan principal amount, paid when loan is funded`}
        classNamesProps={statClassNames}
        flexType="row"
      />
      <StatInfo
        label="NET APR"
        value={`${netApr.toFixed(1)}%`}
        classNamesProps={statClassNames}
        tooltipText="An annual percentage rate that combines collateral yield and loan interest rates to show the total interest cost or yield in one clear rate"
        flexType="row"
      />
      <StatInfo
        label="Total collateral"
        value={`${bnToFixed({
          value: totalCollateralAmount,
          decimals: collateral?.collateral.decimals,
          fractionDigits: 2,
        })} ${pair.collateralTicker}`}
        classNamesProps={statClassNames}
        flexType="row"
      />
      <StatInfo
        label="Total loan value"
        value={
          <DisplayValue
            strictTokenType={pair.marketTokenType}
            value={bnToNumberSafe(borrowAmount)}
          />
        }
        classNamesProps={statClassNames}
        flexType="row"
      />
    </div>
  )
}

type CalculateNetAprParams = {
  offer: LeverageSimpleOffer
  totalCollateralAmount: BN
  userEnteredCollateralAmount: BN
  conversionRate: number
  collateral: CollateralToken
  pair: MultiplyPair
}
const calculateNetApr = ({
  totalCollateralAmount,
  userEnteredCollateralAmount,
  conversionRate,
  offer,
  collateral,
  pair,
}: CalculateNetAprParams) => {
  const totalBorrowAmount = calculateTokenLoanBorrowAmount(
    totalCollateralAmount.sub(userEnteredCollateralAmount),
    conversionRate,
  )

  const aprRate = calcBorrowerTokenAPR(offer.apr.toNumber(), collateral.collateral.interestFee)

  const debtInYear = calculateCurrentInterestSolPure({
    loanValue: bnToNumberSafe(totalBorrowAmount),
    startTime: moment().unix(),
    currentTime: moment().unix() + SECONDS_IN_YEAR,
    rateBasePoints: aprRate,
  })

  //? Wrap if multiple tokens supported
  const collateralYieldPerYear = totalCollateralAmount
    .mul(new BN(pair.collateralYield))
    .div(new BN(BASE_POINTS))
  const collateralYieldPerYearInToken = collateralYieldPerYear
    .mul(new BN(BASE_POINTS))
    .div(new BN(conversionRate * BASE_POINTS))

  const yieldAndDebtDiff = bnToNumberSafe(collateralYieldPerYearInToken) - debtInYear

  const netApr = (yieldAndDebtDiff / bnToNumberSafe(userEnteredCollateralAmount)) * 100

  return netApr || 0
}
