import { useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { web3 } from 'fbonds-core'
import { PUBKEY_PLACEHOLDER } from 'fbonds-core/lib/fbond-protocol/constants'
import { BondOfferV3 } from 'fbonds-core/lib/fbond-protocol/types'

import { useTokenBondOffers } from '@banx/hooks'
import { useTokenMarketsPreview } from '@banx/pages/tokenLending/LendToken'
import { useTokenType } from '@banx/store/common'
import { SyntheticTokenOffer, convertToSynthetic, useSyntheticTokenOffers } from '@banx/store/token'

const MIN_OFFER_SIZE = 1000

export const useMarketOrders = (marketPubkey: string, offerPubkey: string) => {
  const { publicKey } = useWallet()
  const { tokenType } = useTokenType()
  const { offers, isLoading } = useTokenBondOffers({
    marketPubkey: new web3.PublicKey(marketPubkey),
    lendingTokenType: tokenType,
    excludeWallet: publicKey || undefined,
  })

  const { marketsPreview } = useTokenMarketsPreview()

  const processedOffers = useProcessedOffers({
    marketPubkey,
    offers,
    editableOfferPubkey: offerPubkey,
  })

  const sortedOffers = useMemo(() => {
    return [...processedOffers].sort((orderA, orderB) =>
      orderA.collateralsPerToken.sub(orderB.collateralsPerToken).toNumber(),
    )
  }, [processedOffers])

  const bestOffer = useMemo(() => {
    const [firstOffer, secondOffer] = sortedOffers
    const isFirstOfferEditable =
      firstOffer?.publicKey.toBase58() === PUBKEY_PLACEHOLDER || firstOffer?.isEdit
    return isFirstOfferEditable ? secondOffer : firstOffer
  }, [sortedOffers])

  const market = useMemo(() => {
    return marketsPreview.find((market) => market.marketPubkey === marketPubkey)
  }, [marketPubkey, marketsPreview])

  return {
    offers: sortedOffers,
    isLoading,
    bestOffer,
    market,
  }
}

type UseProcessedOffers = (props: {
  offers: BondOfferV3[]
  marketPubkey: string
  editableOfferPubkey: string
}) => SyntheticTokenOffer[]

const useProcessedOffers: UseProcessedOffers = ({ marketPubkey, offers, editableOfferPubkey }) => {
  const { offerByMarketPubkey } = useSyntheticTokenOffers()
  const { publicKey } = useWallet()

  const processedOffers = useMemo(() => {
    const syntheticOffer = offerByMarketPubkey[marketPubkey]

    if (!offers) return []

    const offersConvertedToSynthetic = offers.map((offer) => convertToSynthetic(offer))

    const processedEditableOffers = offersConvertedToSynthetic
      .filter((offer) => offer.publicKey.toBase58() !== editableOfferPubkey)
      //? Filter out offers with a size smaller than the minimum threshold
      .filter((offer) => offer.offerSize > MIN_OFFER_SIZE)
      //? Filter empty offers, but alwaus show user offers
      .filter((offer) => !(offer.offerSize === 0 && offer.assetReceiver !== publicKey?.toBase58()))

    if (syntheticOffer) {
      processedEditableOffers.push(syntheticOffer)
    }

    return processedEditableOffers
  }, [offerByMarketPubkey, marketPubkey, offers, editableOfferPubkey, publicKey])

  return processedOffers
}
