import { Dispatch, FC, SetStateAction, useEffect } from 'react'

import classNames from 'classnames'

import { Slider, SliderProps } from '@banx/components/Slider'

import { MIN_MULTIPLIER_VALUE } from '../../constants'
import { LeverageSimpleOffer } from '../../types'

import styles from './MultiplierSlider.module.less'

type MultiplierSlider = {
  selectedOffer: LeverageSimpleOffer | undefined
  multiplierValue: number
  setMultiplierValue: Dispatch<SetStateAction<number>>
} & Omit<SliderProps, 'value' | 'onChange'>

export const MultiplierSlider: FC<MultiplierSlider> = ({
  selectedOffer,
  multiplierValue,
  setMultiplierValue,
  ...props
}) => {
  const maxMultiplier = selectedOffer?.maxMultiplier || MIN_MULTIPLIER_VALUE

  useEffect(() => {
    if (selectedOffer) {
      //? If prevMaxMultiplier is greater than currentMaxMultiplier, set it to currentMaxMultiplier
      //? Else keep prevMaxMultiplier
      setMultiplierValue((prevMaxMultiplier) => {
        if (prevMaxMultiplier > selectedOffer.maxMultiplier) return selectedOffer.maxMultiplier
        return prevMaxMultiplier
      })
    }
  }, [selectedOffer, setMultiplierValue])

  const sliderDisabled = maxMultiplier <= MIN_MULTIPLIER_VALUE

  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderLabels}>
        <p className={styles.multiplierValueLabel}>
          {'Multiply: '}
          <span className={styles.multiplierValue}>x{multiplierValue}</span>
        </p>
      </div>

      <Slider
        value={multiplierValue}
        onChange={setMultiplierValue}
        min={MIN_MULTIPLIER_VALUE}
        max={selectedOffer?.maxMultiplier || MIN_MULTIPLIER_VALUE}
        step={1}
        marks={{
          [MIN_MULTIPLIER_VALUE]: `x${MIN_MULTIPLIER_VALUE}`,
          [maxMultiplier]: `x${maxMultiplier}`,
        }}
        className={classNames(styles.multiplierSlider, {
          [styles.multiplierSliderDisabled]: sliderDisabled,
        })}
        {...props}
      />
    </div>
  )
}
