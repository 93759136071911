import BN from 'bn.js'
import { web3 } from 'fbonds-core'
import { BASE_POINTS, LOOKUP_TABLE } from 'fbonds-core/lib/fbond-protocol/constants'
import {
  flashLoanBanxSolEnd,
  flashLoanSolBegin,
} from 'fbonds-core/lib/fbond-protocol/functions/banxSol'
import { getMintLrtsInstructions } from 'fbonds-core/lib/fbond-protocol/functions/multiply'
import {
  borrowPerpetualSpl,
  getFullLoanBodyFromBorrowerSendedAmount,
} from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import { offer as tokenOfferUtils } from 'fbonds-core/lib/fbond-protocol/tokenLendingUtils'
import { CreateTxnData, WalletAndConnection } from 'solana-transactions-executor'

import { core } from '@banx/api/tokens'
import { BONDS } from '@banx/constants'
import { MultiplyPair } from '@banx/pages/tokenLending/LeveragePage'
import { sendTxnPlaceHolder } from '@banx/transactions'

export type CreateLrtsLeverageParams = {
  offer: tokenOfferUtils.SimpleOffer
  multiplier: number
  collateralConversionRate: number
  userEnteredCollateralAmount: BN
  totalCollateralAmount: BN
  collateralTokenMeta: core.TokenMeta
  pair: MultiplyPair
}

type CreateLrtsLeverageTxnData = (
  params: CreateLrtsLeverageParams,
  walletAndConnection: WalletAndConnection,
) => Promise<CreateTxnData<CreateLrtsLeverageParams>>

export const createLrtsLeverageTxnData: CreateLrtsLeverageTxnData = async (
  params,
  walletAndConnection,
) => {
  const {
    offer,
    collateralConversionRate,
    totalCollateralAmount,
    multiplier,
    userEnteredCollateralAmount,
    collateralTokenMeta,
    pair,
  } = params
  const { wallet, connection } = walletAndConnection

  const flashLoanCollateralAmount = totalCollateralAmount
    .sub(userEnteredCollateralAmount)
    .add(new BN(1))

  const flashLoanSolAmount = flashLoanCollateralAmount
    .mul(new BN(1e9))
    .div(new BN(collateralConversionRate * 1e9))

  const { instructions: flashLoanSolBeginIxns } = await flashLoanSolBegin({
    accounts: {
      userPubkey: wallet.publicKey,
    },
    args: {
      amount: new BN(flashLoanSolAmount),
    },
    connection,
    sendTxn: sendTxnPlaceHolder,
  })

  const { instructions: flashLoanSolEndIxns } = await flashLoanBanxSolEnd({
    accounts: {
      userPubkey: wallet.publicKey,
    },
    args: {
      amount: new BN(flashLoanSolAmount),
    },
    connection,
    sendTxn: sendTxnPlaceHolder,
  })

  const amountToGetFromBorrowing = getFullLoanBodyFromBorrowerSendedAmount({
    borrowerSendedAmount: flashLoanSolAmount.toNumber(),
    upfrontFeeBasePoints: collateralTokenMeta.upfrontFee,
  })

  const { instructions: borrowIxns, accounts: borrowAccounts } = await borrowPerpetualSpl({
    programId: new web3.PublicKey(BONDS.PROGRAM_PUBKEY),
    accounts: {
      lender: offer.lender,
      userPubkey: walletAndConnection.wallet.publicKey,
      protocolFeeReceiver: new web3.PublicKey(BONDS.ADMIN_PUBKEY),
      bondOffer: offer.publicKey,
      tokenMint: new web3.PublicKey(collateralTokenMeta.mint),
      hadoMarket: new web3.PublicKey(pair.marketPublicKey),
      fraktMarket: new web3.PublicKey(pair.marketPublicKey),
    },
    args: {
      amountToGet: new BN(amountToGetFromBorrowing),
      amountToSend: totalCollateralAmount,
      optimizeIntoReserves: true,
      lendingTokenType: pair.marketTokenType,
      leverageBasePoints: new BN(Math.floor(multiplier * 100 * BASE_POINTS)),
    },

    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  const mintLrtsIxns = getMintLrtsInstructions({
    conversionRate: collateralConversionRate,
    solAmount: new BN(flashLoanSolAmount),
    walletPublicKey: walletAndConnection.wallet.publicKey,
  })

  return {
    params,
    accounts: Object.values(borrowAccounts),
    instructions: [
      ...flashLoanSolBeginIxns,
      ...mintLrtsIxns,
      ...borrowIxns,
      ...flashLoanSolEndIxns,
    ],
    lookupTables: [new web3.PublicKey(LOOKUP_TABLE)],
  }
}
