import { Instruction, createJupiterApiClient } from '@jup-ag/api'
import { BN, web3 } from 'fbonds-core'
import { LOOKUP_TABLE } from 'fbonds-core/lib/fbond-protocol/constants'
import {
  getBurnLrtsInstructions,
  getUnstakedSolAresteaVaulBalance,
} from 'fbonds-core/lib/fbond-protocol/functions/multiply'
import { sellToRepay } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import {
  CreateTxnData,
  SimulatedAccountInfoByPubkey,
  WalletAndConnection,
} from 'solana-transactions-executor'

import { BondTradeTransaction, FraktBond } from '@banx/api/nft'
import { TokenLoan } from '@banx/api/tokens'
import { BONDS, USDC_ADDRESS, WSOL_ADDRESS } from '@banx/constants'
import { caclulateBorrowTokenLoanValue, isBanxSolTokenType } from '@banx/utils'

import { deserializeInstruction } from '../banxSol/helpers'
import { parseAccountInfoByPubkey } from '../functions'
import { sendTxnPlaceHolder } from '../helpers'
import { SSOL_MINT } from '../leverage/lrtsSOL'
import { createRepayTokenLoanTxnData } from './createRepayTokenLoanTxnData'

export type CreateSellToRepayTokenLoanTxnDataParams = {
  loan: TokenLoan
}

type CreateSellToRepayTokenLoanTxnData = (
  params: CreateSellToRepayTokenLoanTxnDataParams,
  walletAndConnection: WalletAndConnection,
) => Promise<CreateTxnData<CreateSellToRepayTokenLoanTxnDataParams>>

export const createSellToRepayTokenLoanTxnData: CreateSellToRepayTokenLoanTxnData = async (
  params,
  walletAndConnection,
) => {
  const { loan } = params
  const { bondTradeTransaction, fraktBond } = loan

  const instructions: web3.TransactionInstruction[] = []
  const signers: web3.Signer[] = []

  const lookupTables: web3.PublicKey[] = [new web3.PublicKey(LOOKUP_TABLE)]
  const accounts: web3.PublicKey[] = []

  const { instructions: sellToRepayIxns } = await sellToRepay({
    programId: new web3.PublicKey(BONDS.PROGRAM_PUBKEY),
    accounts: {
      userPubkey: walletAndConnection.wallet.publicKey,
      bondTradeTransaction: new web3.PublicKey(bondTradeTransaction.publicKey),
      fbond: new web3.PublicKey(fraktBond.publicKey),
      collateralTokenMint: new web3.PublicKey(loan.collateral.mint),
    },
    args: {
      amountToSell: new BN(fraktBond.fbondTokenSupply),
    },
    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  instructions.push(...sellToRepayIxns)

  //TODO: Refactor, move to jup utils
  const jupiterQuoteApi = createJupiterApiClient()

  const quote = await jupiterQuoteApi.quoteGet({
    inputMint: WSOL_ADDRESS,
    outputMint: SSOL_MINT.toBase58(),
    amount: caclulateBorrowTokenLoanValue(loan).toNumber(),
    slippageBps: 300,
    computeAutoSlippage: true,
    swapMode: 'ExactIn',
    onlyDirectRoutes: true,
    asLegacyTransaction: false,
    maxAccounts: 64,
    minimizeSlippage: false,
    dexes: ['Whirlpool'],
  })

  const unstakedSolAresteaVaulBalance = await getUnstakedSolAresteaVaulBalance(
    walletAndConnection.connection,
  )

  const burnInstructions = getBurnLrtsInstructions({
    lrtsAmount: new BN(quote.outAmount),
    walletPublicKey: walletAndConnection.wallet.publicKey,
    undelegateStake: unstakedSolAresteaVaulBalance.lt(new BN(quote.outAmount)),
  })

  instructions.push(...burnInstructions)

  const { instructions: swapInstructions, lookupTables: swapLookupTable } =
    await getSwapInstuctions({
      inputAmount: parseFloat(quote.outAmount),
      inputTokenMint: SSOL_MINT.toBase58(),
      outputTokenMint: isBanxSolTokenType(bondTradeTransaction.lendingToken)
        ? WSOL_ADDRESS
        : USDC_ADDRESS,
      walletAndConnection,
    })

  instructions.push(...swapInstructions)
  lookupTables.push(...swapLookupTable)

  const { instructions: repayTokenLoanIxns, accounts: repayTokenLoanAccounts } =
    await createRepayTokenLoanTxnData({ loan }, walletAndConnection)

  instructions.push(...repayTokenLoanIxns)
  accounts.push(...(repayTokenLoanAccounts ?? []))

  return {
    params,
    accounts,
    instructions,
    signers,
    lookupTables,
  }
}

type GetSwapInstuctions = (params: {
  inputAmount: number
  inputTokenMint: string
  outputTokenMint: string
  walletAndConnection: WalletAndConnection
}) => Promise<{ instructions: web3.TransactionInstruction[]; lookupTables: web3.PublicKey[] }>

const getSwapInstuctions: GetSwapInstuctions = async ({
  inputAmount,
  walletAndConnection,
  inputTokenMint,
  outputTokenMint,
}) => {
  const jupiterQuoteApi = createJupiterApiClient()

  const quote = await jupiterQuoteApi.quoteGet({
    inputMint: inputTokenMint,
    outputMint: outputTokenMint,
    amount: inputAmount,
    slippageBps: 100,
    computeAutoSlippage: true,
    swapMode: 'ExactIn',
    onlyDirectRoutes: true,
    asLegacyTransaction: false,
    maxAccounts: 64,
    minimizeSlippage: false,
    dexes: ['Whirlpool'],
  })

  const {
    setupInstructions: setupPayload,
    swapInstruction: swapInstructionPayload,
    cleanupInstruction: cleanupPayload,
    addressLookupTableAddresses,
  } = await jupiterQuoteApi.swapInstructionsPost({
    swapRequest: {
      quoteResponse: quote,
      userPublicKey: walletAndConnection.wallet.publicKey.toBase58(),
    },
  })

  const instructions: Instruction[] = []

  if (setupPayload.length) {
    instructions.push(...setupPayload)
  }

  if (swapInstructionPayload) {
    instructions.push(swapInstructionPayload)
  }

  if (cleanupPayload) {
    instructions.push(cleanupPayload)
  }

  const lookupTables = addressLookupTableAddresses.map(
    (lookupTableAddress: string) => new web3.PublicKey(lookupTableAddress),
  )

  return { instructions: instructions.map(deserializeInstruction), lookupTables }
}

export const parseSellToRepayTokenLoanSimulatedAccounts = (
  accountInfoByPubkey: SimulatedAccountInfoByPubkey,
) => {
  const results = parseAccountInfoByPubkey(accountInfoByPubkey)

  return {
    bondTradeTransaction: results?.['bondTradeTransactionV3']?.[0] as BondTradeTransaction,
    fraktBond: results?.['fraktBond']?.[0] as FraktBond,
  }
}
