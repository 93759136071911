import { BN } from 'fbonds-core'
import { offer as tokenOfferUtils } from 'fbonds-core/lib/fbond-protocol/tokenLendingUtils'

import { calculateTokensPerCollateral } from '@banx/utils'

//TODO Refactor math to BNs
export const calcMaxMultiplier = ({
  offer,
  maxCollateralToReceive,
  tokenPriceInCollateral, //? collateral conversionRate
  collateralDecimals,
  tokenDecimals,
}: {
  offer: tokenOfferUtils.SimpleOffer
  maxCollateralToReceive: BN
  tokenPriceInCollateral: number
  collateralDecimals: number
  tokenDecimals: number
}) => {
  const maxTokenToGet = offer.maxTokenToGet.toNumber() / 10 ** tokenDecimals
  const tokenPerCollateral =
    (calculateTokensPerCollateral(offer.collateralsPerToken, collateralDecimals).toNumber() /
      10 ** tokenDecimals) *
    //TODO Remove division adjustment
    0.995

  const maxBorrowerCollateralAmount =
    (maxTokenToGet * (1 - tokenPriceInCollateral * tokenPerCollateral)) / tokenPerCollateral

  const maxCollateralToReceiveOffer = maxCollateralToReceive.toNumber() / 10 ** tokenDecimals

  const multiplier = maxCollateralToReceiveOffer / maxBorrowerCollateralAmount

  //? Round down to nearest integer
  return Math.floor(multiplier)
}

export const calculateTokenLoanBorrowAmount = (
  flashLoanCollateralAmount: BN,
  conversionRate: number,
) => {
  return flashLoanCollateralAmount.mul(new BN(1e9)).div(new BN(conversionRate * 1e9))
}
