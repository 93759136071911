import classNames from 'classnames'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'

import { ColumnType } from '@banx/components/Table'
import { HeaderCell, createPercentValueJSX } from '@banx/components/TableComponents'

import { CollateralToken } from '@banx/api/tokens'

import { LeverageSimpleOffer } from '../../types'

import styles from './OrderBook.module.less'

type GetTableColumns = (props: {
  selectedOffer: LeverageSimpleOffer | undefined
  collateral: CollateralToken | undefined
}) => ColumnType<LeverageSimpleOffer>[]

export const getTableColumns: GetTableColumns = ({ selectedOffer, collateral }) => {
  const columns: ColumnType<LeverageSimpleOffer>[] = [
    {
      key: 'mutiplier',
      title: <HeaderCell label="Max multiply" align="left" />,
      render: (offer) => {
        const isOfferSelected = offer.publicKey.toBase58() === selectedOffer?.publicKey.toBase58()

        return (
          <div className={styles.valueWithRadio}>
            <div
              className={classNames(styles.radioCircle, {
                [styles.radioCircleActive]: isOfferSelected,
              })}
            />
            x{offer.maxMultiplier}
          </div>
        )
      },
    },
    {
      key: 'max leverage',
      title: <HeaderCell label="Max leverage" />,
      render: (offer) => {
        const collateralDecimals = collateral?.collateral.decimals || 0
        return (
          <span>
            {(offer.maxCollateralToReceive.toNumber() / 10 ** collateralDecimals).toFixed(2)}
          </span>
        )
      },
    },
    {
      key: 'apr',
      title: <HeaderCell label="APR" />,
      render: (offer) => {
        const marketInterestFee = collateral?.collateral.interestFee || 0
        const aprPercent = calcBorrowerTokenAPR(offer.apr.toNumber(), marketInterestFee) / 100
        return <span>{createPercentValueJSX(aprPercent)}</span>
      },
    },

    //? Additional info fields for debug
    // {
    //   key: 'maxLtv',
    //   title: <HeaderCell label="Max ltv" />,
    //   render: (offer) => {
    //     const ltv = tokenOfferUtils.calcOfferLtv({
    //       collateralsPerToken: offer.collateralsPerToken,
    //       tokenPrice: new BN(tokenPriceInCollateral * 1e9),
    //     })
    //     return <span>{createPercentValueJSX(Math.trunc(ltv.toNumber() / 100))}</span>
    //   },
    // },
    // {
    //   key: 'offerSize',
    //   title: <HeaderCell label="Offer size" />,
    //   render: (offer) => <span>{(offer.maxTokenToGet.toNumber() / 1e9).toFixed(2)}</span>,
    // },
  ]

  return columns
}
