import { FC } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { Navigate, useParams } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { Loader } from '@banx/components/Loader'
import { Tabs, useTabs } from '@banx/components/Tabs'
import TokenInput from '@banx/components/TokenInput'

import { CollateralToken } from '@banx/api/tokens'
import { PATHS } from '@banx/router'

import LeveragePositionsContent from './LeveragePositionsContent'
import { Header } from './components/Header'
import { MultiplierSlider } from './components/MultiplierSlider'
import { OrderBook } from './components/OrderBook'
import { Summary } from './components/Summary'
import { MultiplyPair } from './constants'
import { useLrtsLeverage, useMultiplyPair } from './hooks'

import styles from './LeveragePage.module.less'

export const LeveragePage = () => {
  const { ticker = '' } = useParams<{ ticker: string }>()
  const { collateralToken, pair, isLoading } = useMultiplyPair(ticker)

  const { value: currentTabValue, ...tabsProps } = useTabs({
    tabs: TABS,
    defaultValue: TabName.MULTIPLY,
  })

  if (!isLoading && (!collateralToken || !pair)) {
    return <Navigate to={PATHS.LEVERAGE_BASE} replace={true} />
  }

  return (
    <div className={styles.pageWrapper}>
      <Header title={ticker} />
      {isLoading && <Loader />}
      {pair && collateralToken && (
        <>
          <Tabs value={currentTabValue} {...tabsProps} />
          {currentTabValue === TabName.MULTIPLY && (
            <LeverageContent collateralToken={collateralToken} pair={pair} />
          )}
          {currentTabValue === TabName.POSITIONS && <LeveragePositionsContent pair={pair} />}
        </>
      )}
    </div>
  )
}

enum TabName {
  MULTIPLY = 'multiply',
  POSITIONS = 'positions',
}

const TABS = [
  {
    label: 'Multiply',
    value: TabName.MULTIPLY,
  },
  {
    label: 'Positions',
    value: TabName.POSITIONS,
  },
]

type LeverageContentProps = {
  pair: MultiplyPair
  collateralToken: CollateralToken
}
const LeverageContent: FC<LeverageContentProps> = ({ pair, collateralToken }) => {
  const { connected: walletConnected } = useWallet()

  const {
    collateralAmount,
    setCollateralAmount,

    walletCollateralBalance,
    totalCollateralAmount,
    lrtsConversionRate,

    multiplierValue,
    setMultiplierValue,
    userEnteredCollateralAmount,

    simpleOffers,
    offersLoading,
    selectedOffer,
    setSelectedOffer,

    borrowBtnProps,
    onLeverageBorrow,
  } = useLrtsLeverage({ pair, collateralToken })

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <TokenInput
          label="Your collateral"
          value={collateralAmount}
          onChange={setCollateralAmount}
          selectedToken={
            collateralToken
              ? { collateral: collateralToken.collateral, amount: walletCollateralBalance }
              : undefined
          }
          className={styles.borrowInput}
          disabled={!walletConnected}
        />

        <MultiplierSlider
          selectedOffer={selectedOffer}
          multiplierValue={multiplierValue}
          setMultiplierValue={setMultiplierValue}
          disabled={!walletConnected}
        />

        <div className={styles.footerContent}>
          {!!selectedOffer && (
            <Summary
              selectedOffer={selectedOffer}
              conversionRate={lrtsConversionRate}
              totalCollateralAmount={totalCollateralAmount}
              userEnteredCollateralAmount={userEnteredCollateralAmount}
              pair={pair}
              collateral={collateralToken}
            />
          )}

          <Button
            onClick={onLeverageBorrow}
            className={styles.depositButton}
            disabled={borrowBtnProps.disabled}
          >
            {borrowBtnProps.text}
          </Button>
        </div>
      </div>
      <OrderBook
        simpleOffers={simpleOffers}
        selectedOffer={selectedOffer}
        setSelectedOffer={setSelectedOffer}
        collateral={collateralToken}
        loading={offersLoading}
      />
    </div>
  )
}
