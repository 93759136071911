import { FC, ReactNode } from 'react'

import classNames from 'classnames'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { OnboardingCarousel } from '@banx/components/OnboardingCarousel'

import { CircleCheck, Pencil, SOL, Shield, TableView, USDC } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'
import { isBanxSolTokenType, isUsdcTokenType } from '@banx/utils'

import styles from './LendLandingPage.module.less'

const LendLandingPage = () => {
  const navigate = useNavigate()
  const { tokenType, setTokenType } = useTokenType()

  const handleGoToVaultsPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND_VAULTS, AssetMode.Token, tokenType))
  }

  const handleGoToPlaceOfferPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND_PLACE_OFFER, AssetMode.Token, tokenType))
  }

  const handleGoToLoanMarketPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEND_LOAN_MARKET, AssetMode.Token, tokenType))
  }

  return (
    <div className={styles.landingPageWrapper}>
      <div className={styles.landingPageSection}>
        <div className={styles.introSection}>
          <h3>Lend your assets</h3>
          <p>
            Earn high yield on your SOL or USDC through active or passive lending and get returns
            while your funds are idle
          </p>
        </div>

        <OnboardingCarousel contentType="lendToken" />
      </div>

      <div className={styles.selectTokenContainer}>
        <Button
          onClick={() => setTokenType(LendingTokenType.BanxSol)}
          className={classNames(styles.selectTokenButton, {
            [styles.active]: isBanxSolTokenType(tokenType),
          })}
          variant="tertiary"
          type="circle"
        >
          <SOL /> SOL
        </Button>
        <Button
          onClick={() => setTokenType(LendingTokenType.Usdc)}
          className={classNames(styles.selectTokenButton, {
            [styles.active]: isUsdcTokenType(tokenType),
          })}
          variant="tertiary"
          type="circle"
        >
          <USDC /> USDC
        </Button>
      </div>

      <div className={styles.overviewContainer}>
        <OverviewBlock
          icon={<Shield />}
          title="Vaults"
          apy="10% - 34% APY"
          features={['Passive Yield', 'Low Risk', 'Curated Strategies']}
          buttonText="Deposit to vault"
          onClick={handleGoToVaultsPage}
          tokenType={tokenType}
          disabled={true}
        />
        <OverviewBlock
          icon={<Pencil />}
          title="Place offer"
          apy="Up to 500% APY"
          features={['Flexible Settings', 'Customizable Yield', 'Full Control']}
          buttonText="Create offer"
          onClick={handleGoToPlaceOfferPage}
          tokenType={tokenType}
        />
        <OverviewBlock
          icon={<TableView />}
          title="Loan Market"
          apy="30% - 80% APY"
          features={['Instant yield']}
          buttonText="Explore market"
          onClick={handleGoToLoanMarketPage}
          tokenType={tokenType}
        />
      </div>
    </div>
  )
}

export default LendLandingPage

interface OverviewBlockProps {
  icon: ReactNode
  title: string
  apy: string
  features: string[]
  buttonText: string
  onClick: () => void
  tokenType: LendingTokenType
  disabled?: boolean
}

const OverviewBlock: FC<OverviewBlockProps> = ({
  icon,
  title,
  apy,
  features,
  buttonText,
  onClick,
  disabled,
  tokenType,
}) => (
  <div
    onClick={onClick}
    className={classNames(styles.overviewBlock, { [styles.disabled]: disabled })}
  >
    <div className={styles.overviewBlockHeader}>
      {icon}
      <h3>{title}</h3>
    </div>
    <div className={styles.apyContainer}>
      {isUsdcTokenType(tokenType) ? <USDC /> : <SOL />}
      <span>{apy}</span>
    </div>
    <ul className={styles.featuresList}>
      {features.map((feature, index) => (
        <li key={index} className={styles.featuresListItem}>
          <CircleCheck />
          {feature}
        </li>
      ))}
    </ul>
    <Button variant="secondary">{disabled ? 'Coming soon' : buttonText}</Button>
  </div>
)
