import { useMemo, useState } from 'react'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import { BN, web3 } from 'fbonds-core'
import { offer as tokenOfferUtils } from 'fbonds-core/lib/fbond-protocol/tokenLendingUtils'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { chain, isEmpty, uniqueId } from 'lodash'
import { TxnExecutor } from 'solana-transactions-executor'

import { CollateralToken, core } from '@banx/api/tokens'
import { useTokenBondOffers, useWalletCollateralBalance } from '@banx/hooks'
import { useTokenLoansOptimistic } from '@banx/store/token'
import {
  TXN_EXECUTOR_DEFAULT_OPTIONS,
  createExecutorWalletAndConnection,
  defaultTxnErrorHandler,
} from '@banx/transactions'
import { lrtsSOL } from '@banx/transactions/leverage'
import { parseTokenBorrowSimulatedAccounts } from '@banx/transactions/tokenLending'
import {
  ZERO_BN,
  destroySnackbar,
  enqueueConfirmationError,
  enqueueSnackbar,
  enqueueTransactionSent,
  enqueueWaitingConfirmation,
  getTokenDecimals,
  getTokenUnit,
  stringToBN,
} from '@banx/utils'

import { MIN_MULTIPLIER_VALUE, MULTIPLY_PAIRS, MultiplyPair } from './constants'
import { calcMaxMultiplier, calculateTokenLoanBorrowAmount } from './helpers'
import { LeverageSimpleOffer } from './types'

//TODO create unified function for all collaterals
const { createLrtsLeverageTxnData } = lrtsSOL

type UseLrtsLeverageParams = {
  pair: MultiplyPair
  collateralToken: CollateralToken
}
export const useLrtsLeverage = ({ pair, collateralToken }: UseLrtsLeverageParams) => {
  const wallet = useWallet()
  const { connection } = useConnection()

  const { walletCollateralBalance } = useWalletCollateralBalance(pair.collateralMint)

  const { rate: collateralConversionRate, isLoading: isCollateralConversionRateLoading } =
    useCollateralConversionRate(pair.collateralMint, connection)

  const [multiplierValue, setMultiplierValue] = useState<number>(MIN_MULTIPLIER_VALUE)
  const [collateralAmount, setCollateralAmount] = useState<string>('')
  const [selectedOffer, setSelectedOffer] = useState<LeverageSimpleOffer | undefined>(undefined)

  const {
    offers,
    isLoading: offersLoading,
    updateOrAddOptimisticOffer,
  } = useTokenBondOffers({
    marketPubkey: pair.marketPublicKey,
    lendingTokenType: pair.marketTokenType,
    excludeWallet: wallet?.publicKey || undefined,
  })
  const { add: addLoansOptimistic } = useTokenLoansOptimistic()

  const simpleOffers: LeverageSimpleOffer[] = useMemo(() => {
    if (isEmpty(offers) || collateralConversionRate === 0) return []
    const simpleOffers = chain(offers)
      .map(tokenOfferUtils.convertToSimpleOffer)
      .compact()
      .map((offer) => {
        const maxCollateralToReceive = tokenOfferUtils.calcMaxCollateralToReceive({
          maxTokenToGet: offer.maxTokenToGet,
          collateralsPerToken: offer.collateralsPerToken,
          tokenDecimals: Math.log10(getTokenDecimals(pair.marketTokenType)),
        })

        const maxMultiplier = calcMaxMultiplier({
          offer: offer,
          maxCollateralToReceive,
          tokenPriceInCollateral: collateralConversionRate,
          collateralDecimals: collateralToken.collateral.decimals,
          tokenDecimals: Math.log10(getTokenDecimals(pair.marketTokenType)),
        })

        return {
          ...offer,
          maxMultiplier,
          maxCollateralToReceive,
        }
      })
      .filter(({ maxMultiplier }) => maxMultiplier >= MIN_MULTIPLIER_VALUE)
      .sort((offerA, offerB) => offerA.apr.sub(offerB.apr).toNumber())
      .sort((offerA, offerB) =>
        offerB.maxCollateralToReceive.sub(offerA.maxCollateralToReceive).toNumber(),
      )
      .sort((offerA, offerB) => offerB.maxMultiplier - offerA.maxMultiplier)
      .value()

    //? Set first offer as default
    if (!isEmpty(simpleOffers)) {
      setSelectedOffer(simpleOffers[0])
    }

    return simpleOffers
  }, [collateralToken.collateral.decimals, collateralConversionRate, offers, pair.marketTokenType])

  const userEnteredCollateralAmount = stringToBN(
    collateralAmount,
    collateralToken.collateral.decimals,
  )
  const totalCollateralAmount = userEnteredCollateralAmount.mul(new BN(multiplierValue))

  const maxLeverage = useMemo(() => {
    return selectedOffer?.maxCollateralToReceive || ZERO_BN
  }, [selectedOffer])

  const onLeverageBorrow = async () => {
    const loadingSnackbarId = uniqueId()
    try {
      const walletAndConnection = createExecutorWalletAndConnection({ wallet, connection })

      const txnData = await createLrtsLeverageTxnData(
        {
          collateralConversionRate,
          totalCollateralAmount,
          offer: selectedOffer!,
          multiplier: multiplierValue,
          userEnteredCollateralAmount,
          collateralTokenMeta: collateralToken.collateral,
          pair,
        },
        walletAndConnection,
      )

      await new TxnExecutor<lrtsSOL.CreateLrtsLeverageParams>(
        walletAndConnection,
        TXN_EXECUTOR_DEFAULT_OPTIONS,
      )
        .addTxnData(txnData)
        .on('sentAll', (results) => {
          results.forEach(({ signature }) => enqueueTransactionSent(signature))
          enqueueWaitingConfirmation(loadingSnackbarId)
        })
        .on('confirmedAll', (results) => {
          const { confirmed, failed } = results
          destroySnackbar(loadingSnackbarId)
          if (failed.length) {
            return failed.forEach(({ signature, reason }) =>
              enqueueConfirmationError(signature, reason),
            )
          }

          enqueueSnackbar({ message: 'Borrowed successfully', type: 'success' })

          const { accountInfoByPubkey } = confirmed[0]
          if (!accountInfoByPubkey) return
          const { bondOffer, bondTradeTransaction, fraktBond } =
            parseTokenBorrowSimulatedAccounts(accountInfoByPubkey)
          const optimisticLoan: core.TokenLoan = {
            publicKey: fraktBond.publicKey,
            fraktBond: {
              ...fraktBond,
              hadoMarket: pair.marketPublicKey.toBase58(),
            },
            bondTradeTransaction,
            collateral: collateralToken.collateral,
            collateralPrice: collateralConversionRate,
          }
          //? Add optimistic loans
          if (wallet.publicKey) {
            addLoansOptimistic([optimisticLoan], wallet.publicKey.toBase58())
          }
          updateOrAddOptimisticOffer(bondOffer)
        })
        .on('error', (error) => {
          throw error
        })
        .execute()
    } catch (error) {
      destroySnackbar(loadingSnackbarId)
      defaultTxnErrorHandler(error, {
        additionalData: {
          totalCollateralAmount: totalCollateralAmount.toString(),
        },
        walletPubkey: wallet?.publicKey?.toBase58(),
        transactionName: 'leverageLrtsSOL',
      })
    }
  }

  const borrowBtnProps: { text: string; disabled: boolean } = (() => {
    if (!wallet.connected)
      return {
        disabled: true,
        text: `Connect wallet`,
      }

    if (userEnteredCollateralAmount.lte(ZERO_BN))
      return {
        disabled: true,
        text: 'Enter collateral amount',
      }

    if (
      walletCollateralBalance.lt(stringToBN(collateralAmount, collateralToken.collateral.decimals))
    )
      return { disabled: true, text: 'Insufficient balance' }

    if (maxLeverage.lt(totalCollateralAmount))
      return {
        disabled: true,
        text: 'Max leverage exceeded',
      }

    const totalBorrowAmount = calculateTokenLoanBorrowAmount(
      totalCollateralAmount.sub(userEnteredCollateralAmount),
      collateralConversionRate,
    )

    if (pair.loanValueLimit && totalBorrowAmount.gt(pair.loanValueLimit)) {
      const unitSymbol = getTokenUnit(pair.marketTokenType)
      const limit = pair.loanValueLimit.div(new BN(getTokenDecimals(pair.marketTokenType)))
      return {
        disabled: true,
        text: `${limit} ${unitSymbol} limit reached`,
      }
    }

    return {
      disabled: false,
      text: `Multiply ${pair.collateralTicker}`,
    }
  })()

  return {
    collateralAmount,
    setCollateralAmount,

    walletCollateralBalance,
    totalCollateralAmount,

    multiplierValue,
    setMultiplierValue,

    simpleOffers,
    userEnteredCollateralAmount,
    offersLoading,
    selectedOffer,
    setSelectedOffer,

    lrtsConversionRate: collateralConversionRate,
    isLrtsConversionRateLoading: isCollateralConversionRateLoading,

    onLeverageBorrow,
    borrowBtnProps,
  }
}

export const useCollateralConversionRate = (mint: web3.PublicKey, connection: web3.Connection) => {
  const queryFn = useMemo(() => {
    if (mint === lrtsSOL.LRTS_MINT) return () => lrtsSOL.getSolayerConversionRate(connection)
    return () => Promise.resolve(0)
  }, [connection, mint])

  const { data: rate, isLoading } = useQuery(['conversionRate', mint], () => queryFn(), {
    enabled: !!connection && !!queryFn,
    staleTime: 10 * 1000,
    refetchOnWindowFocus: false,
  })

  return { rate: rate || 0, isLoading }
}

export const useMultiplyPair = (ticker: string) => {
  const { publicKey } = useWallet()

  const pair = useMemo(() => {
    return MULTIPLY_PAIRS.find(({ collateralTicker }) => collateralTicker === ticker)
  }, [ticker])

  const { data, isLoading } = useQuery(
    ['collateralMeta', publicKey, pair],
    () =>
      core.fetchCollateralsList({
        walletPubkey: publicKey?.toBase58(),
        marketType: pair?.marketTokenType || LendingTokenType.BanxSol,
        mint: pair?.collateralMint.toBase58(),
      }),
    {
      enabled: !!pair,
      refetchOnWindowFocus: false,
      staleTime: 30_000,
    },
  )

  const collateralToken: CollateralToken | undefined = useMemo(() => {
    if (!data) return
    return data.find(({ collateral }) => collateral.mint === pair?.collateralMint.toBase58())
  }, [data, pair])

  return { pair, collateralToken, isLoading }
}
