import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { OnboardButton } from '@banx/components/Buttons'

import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'

import styles from './BorrowHeader.module.less'

export const BorrowHeader: FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate()
  const { tokenType } = useTokenType()

  const handleBackToHome = () => {
    navigate(buildUrlWithModeAndToken(PATHS.BORROW, AssetMode.Token, tokenType))
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.navigation} onClick={handleBackToHome}>
          <span onClick={handleBackToHome} className={styles.homePageTitle}>
            Borrow
          </span>
          <span>→</span>
          <span>{title}</span>
        </div>

        <OnboardButton contentType="borrow" />
      </div>
    </div>
  )
}
