import { FC, useCallback, useMemo } from 'react'

import Table from '@banx/components/Table'

import { CollateralToken } from '@banx/api/tokens'

import { LeverageSimpleOffer } from '../../types'
import { getTableColumns } from './getTableColumns'

import styles from './OrderBook.module.less'

interface OrderBookProps {
  simpleOffers: LeverageSimpleOffer[]
  selectedOffer: LeverageSimpleOffer | undefined
  setSelectedOffer: (offer: LeverageSimpleOffer) => void
  collateral: CollateralToken | undefined
  loading: boolean
}

export const OrderBook: FC<OrderBookProps> = ({
  simpleOffers,
  selectedOffer,
  setSelectedOffer,
  collateral,
  loading,
}) => {
  const columns = getTableColumns({ selectedOffer, collateral })

  const onRowClick = useCallback(
    (offer: LeverageSimpleOffer) => {
      setSelectedOffer(offer)
    },
    [setSelectedOffer],
  )

  const rowParams = useMemo(() => {
    return {
      onRowClick,
    }
  }, [onRowClick])

  const hasOffers = simpleOffers.length > 0
  const isLoading = !hasOffers && loading

  return (
    <div className={styles.container}>
      <Table
        data={simpleOffers}
        columns={columns}
        rowParams={rowParams}
        className={styles.table}
        classNameTableWrapper={styles.tableWrapper}
        loading={isLoading}
        loaderClassName={styles.loader}
        emptyMessage={hasOffers ? undefined : 'There are no available offers at the moment'}
      />
    </div>
  )
}

export default OrderBook
