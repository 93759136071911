import { FC } from 'react'

import classNames from 'classnames'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { OnboardingCarousel } from '@banx/components/OnboardingCarousel'
import Tooltip from '@banx/components/Tooltip'

import { Multiply, SOL, StarSecondary, USDC } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'
import { LRTS_TOKEN_STATIC_META } from '@banx/transactions/leverage/lrtsSOL'
import { isBanxSolTokenType, isUsdcTokenType } from '@banx/utils'

import FLPImage from './assets/FLP.png'
import JUPImage from './assets/JUP.png'
import MeteoraImage from './assets/Meteora.png'
import ezSOLImage from './assets/ezSOL.png'

import styles from './LeverageLanding.module.less'

export const LeverageLanding = () => {
  const navigate = useNavigate()
  const { tokenType, setTokenType } = useTokenType()

  const handleGoToLeveragePage = (ticker: string) => {
    const pathname = `${PATHS.LEVERAGE_BASE}/${ticker}`
    navigate(buildUrlWithModeAndToken(pathname, AssetMode.Token, tokenType))
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.mainSection}>
          <div className={styles.mainSectionTitle}>
            <h1>Multiply</h1>
            <p>Boost your yield or leverage any token you want</p>
          </div>
          <OnboardingCarousel contentType="multiply" />
        </div>
        <div className={styles.selectTokenContainer}>
          <Button
            onClick={() => setTokenType(LendingTokenType.BanxSol)}
            className={classNames(styles.selectTokenButton, {
              [styles.active]: isBanxSolTokenType(tokenType),
            })}
            variant="tertiary"
            type="circle"
          >
            <SOL /> SOL
          </Button>
          <Button
            onClick={() => setTokenType(LendingTokenType.Usdc)}
            className={classNames(styles.selectTokenButton, {
              [styles.active]: isUsdcTokenType(tokenType),
            })}
            variant="tertiary"
            type="circle"
          >
            <USDC /> USDC
          </Button>
        </div>

        <div className={styles.tokensList}>
          {TOKEN_ITEMS.map((tokenItem, idx) => (
            <TokenItemLink
              key={idx}
              {...tokenItem}
              onClick={() => handleGoToLeveragePage(tokenItem.ticker)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const TOKEN_ITEMS: TokenItem[] = [
  {
    logoUrl: LRTS_TOKEN_STATIC_META.logoUrl,
    ticker: LRTS_TOKEN_STATIC_META.ticker,
    maxApy: 9,
    maxMultiply: 100,
    //TODO: Fetch extraRewardsDescription from markets rewards json
    extraRewardsDescription: 'This market earns additional rewards: Adrastea restaking',
    disabled: false,
  },
  {
    logoUrl: JUPImage,
    ticker: 'JLP',
    maxMultiply: 100,
    disabled: true,
  },
  {
    logoUrl: FLPImage,
    ticker: 'FLP.1',
    maxMultiply: 100,
    disabled: true,
  },
  {
    logoUrl: MeteoraImage,
    ticker: 'Meteora LP',
    maxMultiply: 100,
    disabled: true,
  },
  {
    logoUrl: ezSOLImage,
    ticker: 'ezSOL',
    maxMultiply: 100,
    disabled: true,
  },
  {
    ticker: 'Any token',
    disabled: true,
  },
]

type TokenItem = {
  logoUrl?: string
  ticker: string
  maxApy?: number
  maxMultiply?: number
  disabled?: boolean
  extraRewardsDescription?: string
}

const TokenItemLink: FC<TokenItem & { onClick: () => void }> = ({
  ticker,
  logoUrl,
  maxApy,
  maxMultiply,
  extraRewardsDescription,
  disabled,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.tokenItem, { [styles.tokenItemDisabled]: disabled })}
    >
      <div className={styles.tokenItemHeader}>
        {logoUrl && <img src={logoUrl} alt={ticker} />}
        <p className={styles.tokenItemTicker}>{ticker}</p>

        {maxApy && (
          <div className={styles.tokenItemApyWrapper}>
            <p className={styles.tokenItemApyTitle}>
              {extraRewardsDescription && (
                <Tooltip title={extraRewardsDescription}>
                  <StarSecondary />
                </Tooltip>
              )}
              Max apy
            </p>
            <p className={styles.tokenItemApyValue}>{maxApy}%</p>
          </div>
        )}
      </div>
      <div className={styles.tokenItemFooter}>
        {maxMultiply && (
          <p className={styles.tokenItemMultiply}>
            <Multiply /> up to {maxMultiply}x multiply
          </p>
        )}
        {!maxMultiply && <p className={styles.tokenItemMultiplyPlaceholder}>Go long on anything</p>}
      </div>
    </div>
  )
}
