import Tooltip from '@banx/components/Tooltip'

import { TOOLTIP_TEXTS } from '../constants'

import styles from '../LeveragePositionsContent.module.less'

export const HeaderList = () => {
  const stats = [
    { label: 'Leverage' },
    { label: 'Debt', tooltipText: TOOLTIP_TEXTS.DEBT },
    { label: 'NET APR', tooltipText: TOOLTIP_TEXTS.APR },
    { label: 'PNL', tooltipText: TOOLTIP_TEXTS.PNL },
  ]

  return (
    <div className={styles.headerList}>
      <div className={styles.headerMainStat}>
        <span className={styles.headerStatLabel}>Position</span>
      </div>

      <div className={styles.headerStats}>
        {stats.map(({ label, tooltipText }, index) => (
          <div key={index} className={styles.headerAdditionalStat}>
            <span className={styles.headerStatLabel}>{label}</span>
            {tooltipText && <Tooltip title={tooltipText} />}
          </div>
        ))}
      </div>
    </div>
  )
}
