import { BN } from 'fbonds-core'
import { BASE_POINTS, SECONDS_IN_YEAR } from 'fbonds-core/lib/fbond-protocol/constants'
import { calculateCurrentInterestSolPure } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import moment from 'moment'

import { TokenLoan } from '@banx/api/tokens'
import { lrtsSOL } from '@banx/transactions/leverage'
import {
  ZERO_BN,
  bnToNumberSafe,
  caclulateBorrowTokenLoanValue,
  calcTokenLoanAprWithRepayFee,
} from '@banx/utils'

export const calculateNetAprByLoan = (loan: TokenLoan, conversionRate: number) => {
  const totalDebt = caclulateBorrowTokenLoanValue(loan)

  const aprRate = calcTokenLoanAprWithRepayFee(loan)

  const debtInYear = calculateCurrentInterestSolPure({
    loanValue: bnToNumberSafe(totalDebt),
    startTime: moment().unix(),
    currentTime: moment().unix() + SECONDS_IN_YEAR,
    rateBasePoints: aprRate,
  })

  //? Wrap if multiple tokens supported
  const collateralYieldPerYear = calcCollateralYieldPerYear(new BN(loan.fraktBond.fbondTokenSupply))
  const collateralYieldPerYearInToken = convertYieldToTokenValue(
    collateralYieldPerYear,
    conversionRate,
  )

  const yieldAndDebtDiff = bnToNumberSafe(collateralYieldPerYearInToken) - debtInYear

  return calcNetAprFromYieldAndDebtDiff(loan, yieldAndDebtDiff)
}

const calcCollateralYieldPerYear = (collateralTokenAmount: BN): BN => {
  return collateralTokenAmount.mul(new BN(lrtsSOL.LRTS_YIELD)).div(new BN(BASE_POINTS))
}

const convertYieldToTokenValue = (collateralYield: BN, conversionRate: number): BN => {
  if (!conversionRate) return ZERO_BN
  return collateralYield.mul(new BN(BASE_POINTS)).div(new BN(conversionRate * BASE_POINTS))
}

const calcNetAprFromYieldAndDebtDiff = (loan: TokenLoan, yieldAndDebtDiff: number): number => {
  const leverage = loan.fraktBond.leverageBasePoints / BASE_POINTS / 100
  return (yieldAndDebtDiff / (loan.fraktBond.fbondTokenSupply / leverage)) * 100
}
