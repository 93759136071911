import { BN, web3 } from 'fbonds-core'
import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { core } from '@banx/api/tokens'

export const LRTS_TOKEN_TICKER = 'lrtsSOL'

export const LRTS_MARKET = new web3.PublicKey('7EuPa26AjGdnQ7JcqM3kFhwFR4U2NQTU9guHcmaDF2G')

// TODO Remove hardcode in future when usdc multiply is implemented
export const LRTS_MARKET_TOKEN_TYPE = LendingTokenType.BanxSol
export const LRTS_YIELD = new BN(0.07 * BASE_POINTS)

export const LRTS_MINT = new web3.PublicKey('4tARAT4ssRYhrENCTxxZrmjL741eE2G23Q1zLPDW2ipf')
export const LP_SOLAYER_MINT = new web3.PublicKey('sSo1wxKKr6zW2hqf5hZrp2CawLibcwi1pMBqk5bg2G4')
export const SSOL_MINT = new web3.PublicKey('sSo14endRuUbvQaJS3dq36Q829a3A6BEfoeeRGJywEh')

export const LRTS_TOKEN_STATIC_META: core.TokenMeta = {
  mint: LRTS_MINT.toBase58(),
  ticker: LRTS_TOKEN_TICKER,
  logoUrl:
    'https://i.degencdn.com/ipfs/bafkreigoloautosntlteqccwusdjpxvtm3nm3kqvkavwxwickrk5irfety',
  decimals: 9,
  priceUsd: 0,
  totalSupply: '0',
  fullyDilutedValuation: '0',
  fullyDilutedValuationInMillions: '0',
  name: 'adraLRT SOL (Solayer)',
  interestFee: 500,
  upfrontFee: 25,
}
