import { FC, SVGProps } from 'react'

export const Shield: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 2.25C12.2021 2.25 12.3957 2.33161 12.5368 2.47633C14.2905 4.27429 16.481 5.25 19.0001 5.25H19.9991C20.4133 5.25 20.75 5.58579 20.75 6V9.16611C20.75 15.1398 17.1792 20.2549 12.2121 21.7194C12.0736 21.7602 11.9264 21.7602 11.7879 21.7194C6.82084 20.2549 3.25 15.1398 3.25 9.16611L3.25009 6.00033L4.00009 6L4.0001 5.25H5.00009C7.51912 5.25 9.7094 4.27424 11.463 2.47632C11.6042 2.33161 11.7978 2.25 11.9999 2.25ZM4.00009 6L4.0001 5.25C3.5859 5.25 3.25011 5.58613 3.25009 6.00033L4.00009 6ZM4.75006 6.75L4.75 9.16611C4.75 14.4886 7.87063 18.8827 12 20.2151C16.1294 18.8827 19.25 14.4886 19.25 9.16611V6.75H19.0001C16.3102 6.75 13.9316 5.78661 11.9999 4.04081C10.0683 5.78653 7.69 6.75 5.00009 6.75H4.75006Z"
      fill="#1B1B1B"
    />
  </svg>
)
