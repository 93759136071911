export const PATHS = {
  ROOT: '/',
  PAGE_404: '/404',

  LEVERAGE_BASE: '/multiply',
  LEVERAGE: '/multiply/:ticker',
  LOANS: '/loans',
  OFFERS: '/offers',

  BORROW: '/borrow',
  BORROW_NOW: '/borrow/now',
  BORROW_LISTING: '/borrow/listing',

  LEND: '/lend',
  LEND_VAULTS: '/lend/vaults',
  LEND_PLACE_OFFER: '/lend/place-offer',
  LEND_LOAN_MARKET: '/lend/loan-market',

  DASHBOARD: '/dashboard',
  ADVENTURES: '/adventures',
  LEADERBOARD: '/leaderboard',
} as const
