import { BN, web3 } from 'fbonds-core'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { lrtsSOL } from '@banx/transactions/leverage'
import { getTokenDecimals } from '@banx/utils'

export const MIN_MULTIPLIER_VALUE = 2

export type MultiplyPair = {
  collateralTicker: string
  collateralMint: web3.PublicKey
  collateralYield: BN //TODO value can be dynamic
  marketTokenType: LendingTokenType
  marketPublicKey: web3.PublicKey
  loanValueLimit?: BN
}
export const MULTIPLY_PAIRS: MultiplyPair[] = [
  {
    collateralTicker: lrtsSOL.LRTS_TOKEN_TICKER,
    collateralMint: lrtsSOL.LRTS_MINT,
    collateralYield: lrtsSOL.LRTS_YIELD,
    marketTokenType: lrtsSOL.LRTS_MARKET_TOKEN_TYPE,
    marketPublicKey: lrtsSOL.LRTS_MARKET,
    loanValueLimit: new BN(120 * getTokenDecimals(lrtsSOL.LRTS_MARKET_TOKEN_TYPE)),
  },
]
