import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'

import styles from './Header.module.less'

export const Header: FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate()
  const { tokenType } = useTokenType()

  const handleBackToHome = () => {
    navigate(buildUrlWithModeAndToken(PATHS.LEVERAGE_BASE, AssetMode.Token, tokenType))
  }

  //TODO Add onboard content

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.navigation} onClick={handleBackToHome}>
          <span onClick={handleBackToHome} className={styles.homePageTitle}>
            Multiply
          </span>
          <span>→</span>
          <span>{title}</span>
        </div>
      </div>
    </div>
  )
}
