import { useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { web3 } from 'fbonds-core'

import { useTokenBondOffers } from '@banx/hooks'
import { useTokenMarketsPreview } from '@banx/pages/tokenLending/LendToken'
import { useTokenType } from '@banx/store/common'

export const useTokenMarketAndOffer = (offerPubkey: string, marketPubkey: string) => {
  const { publicKey } = useWallet()
  const { tokenType } = useTokenType()
  const { offers, updateOrAddOptimisticOffer } = useTokenBondOffers({
    marketPubkey: new web3.PublicKey(marketPubkey),
    lendingTokenType: tokenType,
    excludeWallet: publicKey || undefined,
  })

  const { marketsPreview } = useTokenMarketsPreview()

  const market = useMemo(() => {
    return marketsPreview.find((market) => market.marketPubkey === marketPubkey)
  }, [marketPubkey, marketsPreview])

  const offer = useMemo(() => {
    return offers.find((offer) => offer.publicKey.toBase58() === offerPubkey)
  }, [offers, offerPubkey])

  return { offer, market, updateOrAddOffer: updateOrAddOptimisticOffer }
}
