import { AssetMode } from '@banx/store/common'

import { NAVIGATION_LINKS } from './constants'

export const isActivePath = (pathname = '') => {
  return location.pathname.split('/')[1] === pathname.split('/')[1]
}

export const getNavigationLinks = (assetMode: AssetMode) => {
  const isTokenMode = assetMode === AssetMode.Token
  return NAVIGATION_LINKS.filter(({ tokenOnly }) => !tokenOnly || isTokenMode)
}
