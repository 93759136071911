import { FC } from 'react'

import { useConnection } from '@solana/wallet-adapter-react'
import classNames from 'classnames'
import { web3 } from 'fbonds-core'
import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'

import { Button } from '@banx/components/Buttons'
import { StatInfo, VALUES_TYPES } from '@banx/components/StatInfo'
import { DisplayValue } from '@banx/components/TableComponents'

import { TokenLoan } from '@banx/api/tokens'
import { useTokenLoansTransactions } from '@banx/pages/tokenLending/LoansTokenPage/TokenLoansContent'
import { caclulateBorrowTokenLoanValue, formatCompact, getTokenLoanSupply } from '@banx/utils'

import { useCollateralConversionRate } from '../../../hooks'
import { TOOLTIP_TEXTS } from '../../constants'
import { calculateNetAprByLoan } from '../../helpers'

import styles from './LeveragePositionCard.module.less'

type LeveragePositionCardProps = {
  loan: TokenLoan
}

const LeveragePositionCard: FC<LeveragePositionCardProps> = ({ loan }) => {
  const { sellToRepay } = useTokenLoansTransactions()

  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <PositionMainInfo loan={loan} />

        <div className={styles.additionalContentWrapper}>
          <PositionAdditionalInfo loan={loan} />

          <Button
            onClick={() => sellToRepay(loan)}
            variant="secondary"
            size="medium"
            className={styles.exitButton}
          >
            Exit
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LeveragePositionCard

const PositionMainInfo: FC<{ loan: TokenLoan }> = ({ loan }) => {
  const { ticker: collareralTicker = '', logoUrl: collateralLogoUrl = '' } = loan.collateral

  const collateralSupply = getTokenLoanSupply(loan)

  const formattedTotalCollateralAmount = formatCompact(collateralSupply.toString(), 2)
  const totalCollateralPrice = collateralSupply * loan.collateralPrice

  return (
    <div className={styles.mainInfoContainer}>
      <img src={collateralLogoUrl} className={styles.collateralImage} />
      <div className={styles.mainInfoContent}>
        <div className={styles.mainInfoContentRow}>
          <span>{formattedTotalCollateralAmount}</span>
          <span>{collareralTicker}</span>
        </div>
        <span className={styles.collateralPrice}>
          <DisplayValue
            strictTokenType={loan.bondTradeTransaction.lendingToken}
            value={totalCollateralPrice}
          />
        </span>
      </div>
    </div>
  )
}

const PositionAdditionalInfo: FC<{ loan: TokenLoan }> = ({ loan }) => {
  const { connection } = useConnection()
  const { rate: conversionRate } = useCollateralConversionRate(
    new web3.PublicKey(loan.collateral.mint),
    connection,
  )

  const loanPnl = loan.pnl ?? 0
  const leverage = loan.fraktBond.leverageBasePoints / BASE_POINTS / 100
  const totalDebt = caclulateBorrowTokenLoanValue(loan).toNumber()
  const netApr = calculateNetAprByLoan(loan, conversionRate)

  const isNegativePnl = loanPnl < 0
  const isNegativeNetApr = netApr < 0

  const classNamesProps = {
    container: styles.additionalInfoStat,
    labelWrapper: styles.additionalInfoStatLabelWrapper,
  }

  return (
    <div className={styles.additionalInfoStats}>
      <StatInfo
        label="Leverage"
        value={`x${leverage}`}
        classNamesProps={{ ...classNamesProps, value: styles.leverageStat }}
      />

      <StatInfo
        label="Debt"
        value={
          <DisplayValue
            strictTokenType={loan.bondTradeTransaction.lendingToken}
            value={totalDebt}
          />
        }
        tooltipText={TOOLTIP_TEXTS.DEBT}
        classNamesProps={classNamesProps}
      />

      <StatInfo
        label="Net APR"
        value={netApr.toFixed(1)}
        valueType={VALUES_TYPES.PERCENT}
        tooltipText={TOOLTIP_TEXTS.APR}
        classNamesProps={{
          ...classNamesProps,
          value: classNames(styles.additionalAprStat, { [styles.negative]: isNegativeNetApr }),
        }}
      />

      <StatInfo
        label="PNL"
        value={
          <span>
            {isNegativePnl ? '' : '+'}
            <DisplayValue
              strictTokenType={loan.bondTradeTransaction.lendingToken}
              value={loanPnl}
            />
          </span>
        }
        classNamesProps={{
          ...classNamesProps,
          value: classNames(styles.pnlStat, { [styles.negative]: isNegativePnl }),
        }}
      />
    </div>
  )
}
